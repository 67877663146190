import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "/lesson-plans/"
      }}>{`»`}{` Back to Lesson Plans`}</a></p>
    <p>{`This week make `}<strong parentName="p">{`at least 5 substantive posts`}</strong>{` for this lesson's threads as follows:`}</p>
    <ul>
      <li parentName="ul">{`Reply to at least 3 of the numbered questions posed in the threads below `}<strong parentName="li">{`by 5pm Tuesday.`}</strong></li>
      <li parentName="ul">{`Then keep an eye on these and the other threads and respond to other students' posts or the professor's new questions with at least 2 more replies `}<strong parentName="li">{`by 5pm Friday.`}</strong></li>
    </ul>
    <h2>{`7.1 Understanding People in Interaction and Information Design`}</h2>
    <p>{`Garrett says that “interaction design and information architecture sound like esoteric, highly technical areas, but these disciplines aren't really about technology at all. They're about understanding people--the way they behave and think.” Choose either information architecture or interaction design and explain how this is true.`}</p>
    <h2>{`7.2 Evaluating Information or Interaction Design`}</h2>
    <p>{`Choose a tool you use each day and evaluate either its information architecture or its interaction design using terms and concepts discussed in this chapter.`}</p>
    <h2>{`7.3 Designer Pitfalls Addressed`}</h2>
    <p>{`Garrett describes how software developers can land in a pitfall when building software without thinking about interaction design. Can designers fall into a similar pitfall when thinking about the visual style and aesthetics of a site? How can either interaction design or information architecture address this pitfall?`}</p>
    <h2>{`7.4 Conceptual Models and Conventions`}</h2>
    <p>{`What is the relationship between a conceptual model and a convention according to Garrett? How does this discussion compare to your understanding of conceptual models from Don Norman?`}</p>
    <h2>{`7.5 Stretching Metaphors Like Butter Over Too Much Bread`}</h2>
    <p>{`Do you know examples of sites or tools like the Southwest Airline example in Chapter 5 where a conceptual model or metaphor was pushed too far?`}</p>
    <h2>{`7.6 Handling Errors Well`}</h2>
    <p>{`What is an example of a site or tool you’ve used that handled errors well? Likely you’ll have to think about it a bit since if they’re doing it well you’ve never noticed it! :) But when you’ve thought of such an example consider where you could have made an error and how the system anticipated that and prevented it or gently corrected you.`}</p>
    <h2>{`7.7 Ancient Information Architecture`}</h2>
    <p>{`Garrett claims that information architecture is a very old discipline. Describe a realistic but ancient scenario in which a person was doing this before there was a term for it. Use concepts from this chapter in your description.`}</p>
    <h2>{`7.8 cedarville.edu: top-down or bottom-up?`}</h2>
    <p>{`Do you think Cedarville University’s main public-facing website uses a primarily top-down architecture or a bottom-up architecture? Or is it balanced between the two? Give support for your conclusion.`}</p>
    <h2>{`7.9 Describing Architecture`}</h2>
    <p>{`Describe a site you use frequently in terms of one of the architectural structures Garrett describes in Chapter 5. Also describe the organizational principle(s) you think is/are in play. Finally, do you think the facets they chose to base their organization on were the right ones? Why or why not?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      